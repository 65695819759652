<template>
  <v-select
    v-model="role"
    :placeholder="$t('common.list.selectRole')"
    :items="roles"
    item-value="id"
    single-line
    outlined
    clearable
    hide-details
    :disabled="disabled"
  >
    <template v-slot:selection="{ item, index }">
      {{ t(item.name_translation) }}
    </template>

    <template v-slot:item="{ item }">
      {{ t(item.name_translation) }}
    </template>
  </v-select>
</template>

<script>
import useUserRole from '@/modules/user/composables/userRole'
import { t } from '@/plugins/i18n'
import { computed } from '@vue/composition-api'

export default {
  model: {
    prop: 'roleId',
    event: 'changed',
  },
  props: {
    roleId: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const role = computed({
      get: () => props.roleId,
      set: value => {
        emit('changed', value)
      },
    })

    const { roles } = useUserRole()

    return {
      t,

      role,
      roles,
    }
  },
}
</script>
