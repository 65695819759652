var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formRef",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-sm-h5"
  }, [_vm._t("title", function () {
    return [_vm._v(" Create Distributor User ")];
  })], 2), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  }), _c('v-card-text', [_c('v-tabs', {
    staticClass: "mb-5",
    attrs: {
      "background-color": "deep-purple accent-4",
      "center-active": "",
      "dark": ""
    },
    model: {
      value: _vm.tabRef,
      callback: function callback($$v) {
        _vm.tabRef = $$v;
      },
      expression: "tabRef"
    }
  }, [_c('v-tab', {
    key: "basic"
  }, [_vm._v(" Basic ")]), _c('v-tab', {
    key: "profile"
  }, [_vm._v(" Profile ")]), _c('v-tab', {
    key: "role"
  }, [_vm._v(" Roles & Permissions ")]), _c('v-tab', {
    key: "sso"
  }, [_vm._v(" Single Sign-On ")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tabRef,
      callback: function callback($$v) {
        _vm.tabRef = $$v;
      },
      expression: "tabRef"
    }
  }, [_c('v-tab-item', {
    key: "basic"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Email",
      "outlined": "",
      "rules": [_vm.validators.required],
      "append-icon": "mdi-plus"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Email Verified",
      "color": "success",
      "hide-details": ""
    },
    model: {
      value: _vm.form.email_verified,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email_verified", $$v);
      },
      expression: "form.email_verified"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Country Code",
      "item-text": "title",
      "item-value": "value",
      "outlined": "",
      "items": _vm.countryCodeOptions
    },
    model: {
      value: _vm.form.country_code,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "country_code", $$v);
      },
      expression: "form.country_code"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Phone",
      "outlined": ""
    },
    model: {
      value: _vm.form.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Phone Verified",
      "color": "success",
      "hide-details": ""
    },
    model: {
      value: _vm.form.phone_verified,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phone_verified", $$v);
      },
      expression: "form.phone_verified"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Password",
      "autocomplete": "off",
      "type": _vm.isPasswordVisible ? 'text' : 'password',
      "placeholder": "············",
      "outlined": "",
      "append-icon": _vm.isPasswordVisible ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline,
      "append-outer-icon": _vm.icons.mdiRefresh,
      "rules": _vm.isEditing ? [] : [_vm.validators.required, _vm.validators.passwordValidator]
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.isPasswordVisible = !_vm.isPasswordVisible;
      },
      "click:append-outer": function clickAppendOuter($event) {
        return _vm.newPassword();
      }
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Confirm Password",
      "autocomplete": "off",
      "type": _vm.isConfirmPasswordVisible ? 'text' : 'password',
      "placeholder": "············",
      "outlined": "",
      "append-icon": _vm.isConfirmPasswordVisible ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline,
      "rules": _vm.isEditing ? [] : [_vm.validators.required, _vm.validators.passwordValidator]
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.isConfirmPasswordVisible = !_vm.isConfirmPasswordVisible;
      }
    },
    model: {
      value: _vm.form.password_confirmation,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password_confirmation", $$v);
      },
      expression: "form.password_confirmation"
    }
  })], 1), !_vm.isEditing ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('distributor-id-filter', {
    model: {
      value: _vm.form,
      callback: function callback($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1) : _vm._e(), !_vm.isEditing ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('team-id-filter', {
    ref: "teamFilterRef",
    attrs: {
      "distributor-id": _vm.form.distributor_id
    },
    model: {
      value: _vm.form,
      callback: function callback($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Accessibility",
      "item-text": "title",
      "item-value": "value",
      "outlined": "",
      "items": _vm.accessibilityOptions,
      "rules": [_vm.validators.required]
    },
    model: {
      value: _vm.form.accessibility,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "accessibility", $$v);
      },
      expression: "form.accessibility"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Type",
      "items": _vm.typeOptions,
      "item-text": "title",
      "item-value": "value",
      "outlined": "",
      "rules": [_vm.validators.required]
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.localeOptions,
      "item-text": "title",
      "item-value": "value",
      "label": "Locale",
      "outlined": ""
    },
    model: {
      value: _vm.form.locale,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "locale", $$v);
      },
      expression: "form.locale"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" Note: ")]), _c('ol', [_c('li', [_vm._v("You may create a “Reserved” EB admin user, so that you can login as the user and use EB admin’s features. A “Reserved” user cannot be managed from frontend nor will it be counted as an active User. Only “Standard” user will be counted as an active user.")])])])], 1)], 1), _c('v-tab-item', {
    key: "profile"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "First Name",
      "outlined": ""
    },
    model: {
      value: _vm.form.profile.first_name,
      callback: function callback($$v) {
        _vm.$set(_vm.form.profile, "first_name", $$v);
      },
      expression: "form.profile.first_name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Last Name",
      "outlined": ""
    },
    model: {
      value: _vm.form.profile.last_name,
      callback: function callback($$v) {
        _vm.$set(_vm.form.profile, "last_name", $$v);
      },
      expression: "form.profile.last_name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Gender",
      "items": _vm.genderOptions,
      "item-text": "title",
      "item-value": "value",
      "outlined": ""
    },
    model: {
      value: _vm.form.profile.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.form.profile, "gender", $$v);
      },
      expression: "form.profile.gender"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "readonly": "",
            "label": "Birth Date",
            "persistent-hint": "",
            "outlined": ""
          },
          model: {
            value: _vm.form.profile.birth_date,
            callback: function callback($$v) {
              _vm.$set(_vm.form.profile, "birth_date", $$v);
            },
            expression: "form.profile.birth_date"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.isDatePickerVisible,
      callback: function callback($$v) {
        _vm.isDatePickerVisible = $$v;
      },
      expression: "isDatePickerVisible"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "color": "primary"
    },
    on: {
      "input": function input($event) {
        _vm.isDatePickerVisible = false;
      }
    },
    model: {
      value: _vm.form.profile.birth_date,
      callback: function callback($$v) {
        _vm.$set(_vm.form.profile, "birth_date", $$v);
      },
      expression: "form.profile.birth_date"
    }
  })], 1)], 1)], 1)], 1), _c('v-tab-item', {
    key: "role"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('role-filter', {
    model: {
      value: _vm.form.role_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "role_id", $$v);
      },
      expression: "form.role_id"
    }
  })], 1)], 1)], 1), _c('v-tab-item', {
    key: "sso"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Distributor User ID",
      "outlined": ""
    },
    model: {
      value: _vm.form.distributor_user_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "distributor_user_id", $$v);
      },
      expression: "form.distributor_user_id"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "error",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.onClose
    }
  }, [_vm._v(" Close ")]), _c('v-btn', {
    attrs: {
      "type": "submit",
      "color": "primary",
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_vm._v(" Submit ")])], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }