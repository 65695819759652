<template>
  <v-form
    ref="formRef"
    @submit.prevent="onSubmit"
  >
    <v-card>
      <v-card-title class="text-sm-h5">
        <slot name="title">
          Create Distributor User
        </slot>
      </v-card-title>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>

      <v-card-text>
        <v-tabs
          v-model="tabRef"
          background-color="deep-purple accent-4"
          center-active
          dark
          class="mb-5"
        >
          <v-tab key="basic">
            Basic
          </v-tab>

          <v-tab key="profile">
            Profile
          </v-tab>

          <v-tab key="role">
            Roles & Permissions
          </v-tab>

          <v-tab key="sso">
            Single Sign-On
          </v-tab>

          <!-- <v-tab key="relationship" v-show="form.distributor_id">
            Relationship
          </v-tab> -->
        </v-tabs>

        <v-tabs-items v-model="tabRef">
          <v-tab-item key="basic">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="form.email"
                  label="Email"
                  outlined
                  :rules="[validators.required]"
                  append-icon="mdi-plus"
                >
                  <template v-slot:append>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-checkbox
                  v-model="form.email_verified"
                  label="Email Verified"
                  color="success"
                  hide-details
                />
              </v-col>

              <v-col cols="6">
                <v-row>
                  <v-col cols="5">
                    <v-select
                      v-model="form.country_code"
                      label="Country Code"
                      item-text="title"
                      item-value="value"
                      outlined
                      :items="countryCodeOptions"
                    ></v-select>
                  </v-col>

                  <v-col cols="7">
                    <v-text-field
                      v-model="form.phone"
                      label="Phone"
                      outlined
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="6">
                <v-checkbox
                  v-model="form.phone_verified"
                  label="Phone Verified"
                  color="success"
                  hide-details
                />
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="form.password"
                  label="Password"
                  autocomplete="off"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  placeholder="············"
                  outlined
                  :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  :append-outer-icon="icons.mdiRefresh"
                  :rules="isEditing ? [] : [validators.required, validators.passwordValidator]"
                  @click:append="isPasswordVisible = !isPasswordVisible"
                  @click:append-outer="newPassword()"
                />
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="form.password_confirmation"
                  label="Confirm Password"
                  autocomplete="off"
                  :type="isConfirmPasswordVisible ? 'text' : 'password'"
                  placeholder="············"
                  outlined
                  :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  :rules="isEditing ? [] : [validators.required, validators.passwordValidator]"
                  @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                />
              </v-col>

              <v-col
                v-if="! isEditing"
                cols="6"
              >
                <distributor-id-filter v-model="form" />
              </v-col>

              <v-col
                v-if="! isEditing"
                cols="6"
              >
                <team-id-filter
                  ref="teamFilterRef"
                  v-model="form"
                  :distributor-id="form.distributor_id"
                />
              </v-col>

              <v-col cols="4">
                <v-select
                  v-model="form.accessibility"
                  label="Accessibility"
                  item-text="title"
                  item-value="value"
                  outlined
                  :items="accessibilityOptions"
                  :rules="[validators.required]"
                />
              </v-col>

              <v-col cols="4">
                <v-select
                  v-model="form.type"
                  label="Type"
                  :items="typeOptions"
                  item-text="title"
                  item-value="value"
                  outlined
                  :rules="[validators.required]"
                />
              </v-col>

              <v-col cols="4">
                <v-select
                  v-model="form.locale"
                  :items="localeOptions"
                  item-text="title"
                  item-value="value"
                  label="Locale"
                  outlined
                />
              </v-col>

              <v-col cols="12">
                <p>
                  Note:
                </p>

                <ol>
                  <li>You may create a “Reserved” EB admin user, so that you can login as the user and use EB admin’s features. A “Reserved” user cannot be managed from frontend nor will it be counted as an active User. Only “Standard” user will be counted as an active user.</li>
                </ol>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item key="profile">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="form.profile.first_name"
                  label="First Name"
                  outlined
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.profile.last_name"
                  label="Last Name"
                  outlined
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="form.profile.gender"
                  label="Gender"
                  :items="genderOptions"
                  item-text="title"
                  item-value="value"
                  outlined
                />
              </v-col>
              <v-col cols="6">
                <v-menu
                  v-model="isDatePickerVisible"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.profile.birth_date"
                      readonly
                      v-bind="attrs"
                      label="Birth Date"
                      persistent-hint
                      outlined
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="form.profile.birth_date"
                    color="primary"
                    @input="isDatePickerVisible = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item key="role">
            <v-row>
              <v-col cols="6">
                <role-filter v-model="form.role_id" />
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item key="sso">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="form.distributor_user_id"
                  label="Distributor User ID"
                  outlined
                />
              </v-col>
            </v-row>
          </v-tab-item>

          <!--<v-tab-item key="relationship" v-show="form.distributor_id">
            <v-row>
              <v-col cols="6">
                  <v-select v-model="form.parent_id" label="Parent User" item-text="title"
                    item-value="value" outlined :items="userOptions" />
              </v-col>

              <v-col cols="6">
                  <search-user
                    :query="user && user.parent ? user.parent.email : ''"
                    @options="makeUserOptions"
                    @selectParentUserById="selectParentUserById"
                    outlined
                  />
              </v-col>
              <v-col cols="12">
                <p>
                  Note:
                </p>

                <ol>
                  <li>User who can purchase pass & product <b>SHOULD NOT</b> set parent user. </li>
                  <li>You don't need to parent user to user with agent role</li>
                </ol>
              </v-col>
            </v-row>
          </v-tab-item>-->
        </v-tabs-items>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="error"
          :disabled="loading"
          @click="onClose"
        >
          Close
        </v-btn>
        <v-btn
          type="submit"
          color="primary"
          :loading="loading"
          :disabled="loading"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { t } from '@/plugins/i18n'
import { emailValidator, passwordValidator, required } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline, mdiPlus, mdiRefresh } from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'

// Components
import { DistributorIdFilter, TeamIdFilter } from '@/components'
import { useGeneratePassword, useIgnoreNullValue } from '@/composables'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import RoleFilter from './filters/RoleFilter.vue'

dayjs.extend(utc)

export default {
  components: {
    DistributorIdFilter,
    TeamIdFilter,
    RoleFilter,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: null,
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
  },

  setup(props, { emit }) {
    const isEditing = ref(false)
    const isPasswordVisible = ref(false)
    const isConfirmPasswordVisible = ref(false)
    const isDatePickerVisible = ref(false)

    const tabRef = ref(null)
    const teamFilterRef = ref(null)

    const defaultFormValue = {
      email: null,
      country_code: '852',
      phone: null,
      email_verified: false,
      phone_verified: false,
      password: null,
      password_confirmation: null,
      distributor_id: null,
      team_id: null,
      distributor_user_id: null,
      accessibility: 'std',
      type: null,
      locale: 'en',
      profile: {
        first_name: null,
        last_name: null,
        gender: 'unknown',
        birth_date: null,
      },
      role_id: null,
      parent_id: null,
      active: true,
    }

    const form = ref({
      ...defaultFormValue,
    })
    const formRef = ref(null)

    const userOptions = ref([])

    const makeUserOptions = options => {
      userOptions.value = [
        {
          title: 'Main User - can purchase pass',
          value: null,
        },
        ...options,
      ]
    }

    const selectParentUserById = id => {
      form.value.parent_id = id
    }

    const genderOptions = [
      {
        title: 'Male',
        value: 'male',
      },
      {
        title: 'Female',
        value: 'female',
      },
      {
        title: 'Unknown',
        value: 'unknown',
      },
    ]

    // TODO: global locale list
    const localeOptions = [
      {
        title: 'English',
        value: 'en',
      },
      {
        title: 'Traditional Chinese',
        value: 'tc',
      },
      {
        title: 'Simplified Chinese',
        value: 'sc',
      },
    ]

    const typeOptions = [
      {
        title: 'Direct user',
        value: 'direct_user',
      },
      {
        title: 'Channel user',
        value: 'channel_user',
      },
      {
        title: 'Agent',
        value: 'agent',
      },
      {
        title: 'Policy holder',
        value: 'policy_holder',
      },
      {
        title: 'Employee',
        value: 'employee',
      },
    ]

    const countryCodeOptions = [
      {
        title: '852',
        value: '852',
      },
      {
        title: '853',
        value: '853',
      },
      {
        title: '86',
        value: '86',
      },
    ]

    const accessibilityOptions = [
      {
        title: 'Standard',
        value: 'std',
      },
      {
        title: 'Reserved',
        value: 'reserved',
      },
    ]

    const newPassword = () => {
      form.value.password = useGeneratePassword()
      form.value.password_confirmation = form.value.password
    }

    const onClose = () => {
      resetForm()

      emit('close')
    }

    const onSubmit = () => {
      // validate form
      if (formRef.value.validate()) {
        const data = useIgnoreNullValue(form.value)

        if (isEditing.value) {
          emit('submit', {
            id: props.user.id,
            data,
          })
        } else {
          emit('submit', data)
        }
      }
    }

    const resetForm = () => {
      if (isEditing.value) {
        formRef.value.resetValidation()
        form.value.password = null
        form.value.password_confirmation = null
      } else {
        formRef.value.reset()
        tabRef.value = 'basic'
        Object.assign(form.value, defaultFormValue)

        newPassword()
      }
    }

    onMounted(() => {
      if (props.user) {
        isEditing.value = true

        form.value = mergeUserWithDefaultValue(props.user)
      }
    })

    const mergeUserWithDefaultValue = user => {
      const data = {}

      // merge props.user to form.value with matched keys
      Object.keys(defaultFormValue).forEach(key => {
        if (props.user[key] !== undefined) {
          data[key] = props.user[key]
        }
      })

      return {
        ...data,
        email_verified: user.email_verified_at !== null,
        phone_verified: user.phone_verified_at !== null,
        password: null,
        password_confirmation: null,
        role_id: user.roles[0]?.id,
      }
    }

    watch(
      () => form.value.distributor_id,
      newVal => {
        if (!isEditing.value) {
          form.value.team_id = null

          // teamFilterRef.value.distributorUpdated(newVal)
        }
      },
    )

    return {
      t,

      tabRef,
      teamFilterRef,

      isEditing,
      isPasswordVisible,
      isConfirmPasswordVisible,
      isDatePickerVisible,

      selectParentUserById,

      form,
      formRef,

      userOptions,
      makeUserOptions,

      genderOptions,
      countryCodeOptions,
      typeOptions,

      localeOptions,
      accessibilityOptions,

      newPassword,

      onClose,
      onSubmit,
      resetForm,

      // Rules
      validators: {
        required,
        emailValidator,
        passwordValidator,
      },

      icons: {
        mdiPlus,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiRefresh,
      },
    }
  },
}
</script>
